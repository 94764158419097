
import {defineComponent, ref} from 'vue';

export default defineComponent({
  name: 'DropZoneUpload',
  props: {
    accept: {
      type: String,
      default: '',
    },
  },
  emits: ['file-uploaded'],
  setup(props, { emit }) {
    const file = ref<File | null>(null);
    const isDragging = ref(false);
    const fileInput = ref<HTMLInputElement | null>(null);

    const handleDrop = (event: DragEvent) => {
      event.preventDefault();
      const newFile = event?.dataTransfer?.files[0];
      if (newFile) {
        file.value = newFile;
        emit('file-uploaded', newFile);
      }
      isDragging.value = false;
    };

    const handleFileInput = (event: Event) => {
      const input = event.target as HTMLInputElement;
      if (input.files) {
        file.value = input.files[0];
        emit('file-uploaded', input.files[0]);
      }
    };

    const handleClick = () => {
      if (fileInput.value) {
        fileInput.value.click();
      }
    };

    return { file, isDragging, fileInput, handleDrop, handleFileInput, handleClick };
  },
});
