import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, withModifiers as _withModifiers, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3ea901b9"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["accept"]
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "file-drop-zone",
    onDragenter: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (_ctx.isDragging = true), ["prevent"])),
    onDragover: _cache[2] || (_cache[2] = _withModifiers(() => {}, ["prevent"])),
    onDragleave: _cache[3] || (_cache[3] = _withModifiers(($event: any) => (_ctx.isDragging = false), ["prevent"])),
    onDrop: _cache[4] || (_cache[4] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.handleDrop && _ctx.handleDrop(...args)), ["prevent"])),
    onClick: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.handleClick && _ctx.handleClick(...args)))
  }, [
    _createElementVNode("input", {
      ref: "fileInput",
      type: "file",
      accept: _ctx.accept,
      style: {"display":"none"},
      onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleFileInput && _ctx.handleFileInput(...args)))
    }, null, 40, _hoisted_1),
    (!_ctx.file)
      ? (_openBlock(), _createElementBlock("p", _hoisted_2, "Drag and drop a file here or click to select a file"))
      : (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString(_ctx.file.name), 1))
  ], 32))
}