
import {computed, defineComponent, reactive, ref} from "vue";
import QuickAction from "@/components/base/action/QuickAction.vue";
import {LoadFilterObjects} from "@/core/composite/composite";
import {Actions} from "@/store/enums/StoreEnums";
import store from "@/store";
import BaseModal from "@/components/base/modal/BaseModal.vue";
import FormActivity from "@/views/activity/FormActivity.vue";
import Pagination from "@/components/base/paging/Pagination.vue";
import Avatar from "@/components/base/common/Avatar.vue";
import DateTimeFormat from "@/components/base/datetime/DateTimeFormat.vue";

export default defineComponent({
  name: "Reviews",
  components: {DateTimeFormat, Avatar, Pagination, FormActivity, BaseModal, QuickAction},
  props: {
    cazeId: {type: String, required: true},
  },
  setup(props) {
    const state = reactive({
      formTitle: 'Add Activity',
      activity: {
        type: 'CM_REVIEW',
        occurDt: new Date().getTime(),
        dueDt: '',
        duration: 5,
        note: '',
        title: '',
        status: 'DONE',
        area: 'GENERIC',
        id: '',
        cazeId: props.cazeId,
      },
    })
    const filterObject = ref({type: 'CM_REVIEW', cazeId: props.cazeId})
    const page = computed(() => store.state.ActivityModule.searchPage);
    return {
      filterObject,
      page,
      ...LoadFilterObjects(Actions.ACTIVITY_SEARCH_PAGE, filterObject.value, ['user']),
      state,
    }
  },
  methods: {
    onAdd() {
      this.state.formTitle = 'Add Review';
      this.state.activity.type = "CM_REVIEW";
      this.state.activity.note = ''
      this.state.activity.title = ''
      this.state.activity.occurDt = new Date().getTime();
      this.state.activity.dueDt = '';
      this.state.activity.status = 'DONE'
      this.state.activity.id = ''
      this.state.activity.area = 'GENERIC'
      const modal = this.$refs.formReviewRef as typeof BaseModal;
      modal.showBaseModal();
    },
    onEdit(activity) {
      this.state.formTitle = 'Edit Review';
      this.state.activity.type = "CM_REVIEW";
      this.state.activity.note = activity.note
      this.state.activity.title = activity.title
      this.state.activity.occurDt = new Date().getTime();
      this.state.activity.dueDt = '';
      this.state.activity.status = 'DONE'
      this.state.activity.id = activity.id
      this.state.activity.area = 'GENERIC'
      const modal = this.$refs.formReviewRef as typeof BaseModal;
      modal.showBaseModal();
    },
    onCreated() {
      const baseModal = this.$refs.formReviewRef as typeof BaseModal
      baseModal.hideBaseModal()
      this.paginationLoad();
    }
  }
})
